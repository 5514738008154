import {
  createRouter,
  createWebHashHistory,
  createWebHistory,
  RouteRecordRaw,
} from "vue-router";
import store from "@/store";
import { Mutations, Actions } from "@/store/enums/StoreEnums";
import JwtService from "@/core/services/JwtService";
import { getUserPermissions } from "@/core/config/MainMenuConfig";
import { end } from "@popperjs/core";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    redirect: "/dashboard",
    component: () => import("@/layout/Layout.vue"),
    children: [
      {
        path: "/dashboard",
        name: "dashboard",
        component: () => import("@/views/Dashboard.vue"),
      },
      {
        path: "/geovision",
        name: "geovision",
        component: () => import("@/views/CarteGeovision.vue"),
        meta: {
          module: "geovision",
          action: "read",
        },
      },
      {
        path: "/tm_dashboard",
        name: "tm_dashboard",
        component: () => import("@/views/tm_dash.vue"),
        meta: {
          module: "top management dashboard",
          action: "read",
        },
      },
      {
        path: "/docs/:module?",
        name: "documentations",
        component: () =>
          import("@/views/apps/documentation/DocumentationView.vue"),
      },
      // {
      //   path: "/dahsboard/actions",
      //   name: "dashboard_actions",
      //   component: () => import("@/views/apps/dashboard/ActionsIndicateur.vue"),
      // },

      /* -------------------------------------------------------------------------- */
      /*                            module analyse start                            */
      /* -------------------------------------------------------------------------- */
      {
        path: "/apps/analyse",
        name: "apps-analyse-root",
        redirect: { name: "apps-analyse-analyse-env-list" },
        children: [
          {
            path: "analyse-env-list",
            name: "apps-analyse-analyse-env-list",
            component: () => import("@/views/apps/analyse/AnalyseEnvList.vue"),
            meta: {
              module: "view_env_analysis",
              action: "read",
            },
          },
          {
            path: "add-analyse",
            name: "apps-analyse-add-analyse",
            component: () => import("@/views/apps/analyse/AddAnalyse.vue"),
            meta: {
              module: "view_env_analysis",
              action: "write",
            },
          },
          {
            path: "edit-analyse/:id",
            name: "apps-analyse-edit-analyse",
            component: () => import("@/views/apps/analyse/AddAnalyse.vue"),
            meta: {
              module: "view_env_analysis",
              action: "write",
            },
          },
          {
            path: "show-analyse/:id",
            name: "apps-analyse-show-analyse",
            component: () => import("@/views/apps/analyse/AnalyseEnvShow.vue"),
            meta: {
              module: "view_env_analysis",
              action: "read",
            },
          },
          {
            path: "add-action-analyse/:id",
            name: "apps-analyse-add-action-analyse",
            component: () =>
              import("@/views/apps/analyse/AddActionAnalyse.vue"),
            meta: {
              module: "env_analysis_action_plan",
              action: "write",
            },
          },
        ],
      },
      {
        path: "/apps/analyse",
        name: "apps-analyse-action-root",
        redirect: { name: "apps-analyse-list-action" },
        children: [
          {
            path: "list-action/:id?",
            name: "apps-analyse-list-action",
            component: () => import("@/views/apps/analyse/PlanActionList.vue"),
            meta: {
              module: "env_analysis_action_plan",
              action: "read",
            },
          },
          {
            path: "add-action",
            name: "apps-analyse-add-action",
            component: () =>
              import("@/views/apps/analyse/AddActionAnalyse.vue"),
            meta: {
              module: "env_analysis_action_plan",
              action: "write",
            },
          },
          {
            path: "edit-action-analyse/:id",
            name: "apps-analyse-edit-action-analyse",
            component: () =>
              import("@/views/apps/analyse/EditActionAnalyse.vue"),
            meta: {
              module: "env_analysis_action_plan",
              action: "write",
            },
          },
        ],
      },
      {
        path: "/apps/analyse/indicateur-chart",
        name: "apps-analyse-indicateur-chart",
        component: () => import("@/views/apps/analyse/IndicateurCharts.vue"),
        meta: {
          module: "env_analysis_indicators",
          action: "read",
        },
      },
      /* -------------------------------------------------------------------------- */
      /*                             module analyse end                             */
      /* -------------------------------------------------------------------------- */
      /* -------------------------------------------------------------------------- */
      /*                         module reglementation start                        */
      /* -------------------------------------------------------------------------- */

      {
        path: "/apps/reglementation",
        name: "apps-reglementation-root",
        redirect: { name: "apps-reglementation-reglementation-list" },
        children: [
          {
            path: "reglementation-list",
            name: "apps-reglementation-reglementation-list",
            component: () =>
              import("@/views/apps/reglementation/ReglementationList.vue"),
            meta: {
              module: "view_regulatory_requirements",
              action: "read",
            },
          },
          {
            path: "/apps/reglementation/reglementation-add",
            name: "apps-reglementation-reglementation-add",
            component: () =>
              import("@/views/apps/reglementation/AddReglementation.vue"),
            meta: {
              module: "view_regulatory_requirements",
              action: "write",
            },
          },
          {
            path: "/apps/reglementation/reglementation-edit/:id",
            name: "apps-reglementation-reglementation-edit",
            component: () =>
              import("@/views/apps/reglementation/AddReglementation.vue"),
            meta: {
              module: "view_regulatory_requirements",
              action: "write",
            },
          },
          {
            path: "/apps/reglementation/reglementation-show/:id",
            name: "apps-reglementation-reglementation-show",
            component: () =>
              import("@/views/apps/reglementation/ReglementationShow.vue"),
            meta: {
              module: "view_regulatory_requirements",
              action: "read",
            },
          },
        ],
      },
      {
        path: "/apps/reglementation",
        name: "apps-autre-reglementation-root",
        redirect: { name: "apps-reglementation-autre-reglementation-list" },
        children: [
          {
            path: "autre-reglementation-list",
            name: "apps-reglementation-autre-reglementation-list",
            component: () =>
              import("@/views/apps/reglementation/AutreReglementationList.vue"),
            meta: {
              module: "view_other_requirements",
              action: "read",
            },
          },
          {
            path: "autre-reglementation-add",
            name: "apps-reglementation-autre-reglementation-add",
            component: () =>
              import("@/views/apps/reglementation/AddAutreReglementation.vue"),
            meta: {
              module: "view_other_requirements",
              action: "write",
            },
          },
          {
            path: "autre-reglementation-edit/:id",
            name: "apps-reglementation-autre-reglementation-edit",
            component: () =>
              import("@/views/apps/reglementation/AddAutreReglementation.vue"),
            meta: {
              module: "view_other_requirements",
              action: "write",
            },
          },
          {
            path: "autre-reglementation-show/:id",
            name: "apps-autre-reglementation-show",
            component: () =>
              import("@/views/apps/reglementation/ReglementationShow.vue"),
            meta: {
              module: "view_regulatory_requirements",
              action: "read",
            },
          },
        ],
      },
      {
        path: "/apps/reglementation/autorisations",
        name: "apps-reglementation-autorisation",
        component: () =>
          import("@/views/apps/reglementation/AutorisationList.vue"),
        meta: {
          module: "authorizations",
          action: "read",
        },
      },
      {
        path: "/apps/reglementation",
        name: "apps-reglementation-actions-root",
        redirect: { name: "apps-reglementation-list-action" },
        children: [
          {
            path: "list-action/:id?",
            name: "apps-reglementation-list-action",
            component: () =>
              import("@/views/apps/reglementation/PlanActionList.vue"),
            meta: {
              module: "requirement_action_plan",
              action: "read",
            },
          },
          {
            path: "add-action",
            name: "apps-reglementation-add-action",
            component: () =>
              import("@/views/apps/reglementation/AddAction.vue"),
            meta: {
              module: "requirement_action_plan",
              action: "write",
            },
          },
          {
            path: "edit-action-reglementation/:id",
            name: "apps-reglementation-edit-action-reglementation",
            component: () =>
              import("@/views/apps/reglementation/EditAction.vue"),
            meta: {
              module: "requirement_action_plan",
              action: "write",
            },
          },
          {
            path: ":id_reg/add-action",
            name: "apps-reglementation-add-action-id_reg",
            component: () =>
              import("@/views/apps/reglementation/AddAction.vue"),
            meta: {
              module: "requirement_action_plan",
              action: "write",
            },
          },
        ],
      },
      {
        path: "/apps/reglementation/indicateur-chart",
        name: "apps-reglementation-indicateur-chart",
        component: () =>
          import("@/views/apps/reglementation/IndicateurCharts.vue"),
        meta: {
          module: "requirement_indicators",
          action: "read",
        },
      },

      /* -------------------------------------------------------------------------- */
      /*                          module reglementation end                         */
      /* -------------------------------------------------------------------------- */
      /* -------------------------------------------------------------------------- */
      /*                           module monitoring start                          */
      /* -------------------------------------------------------------------------- */
      {
        path: "/apps/monitoring",
        name: "apps-monitoring-root",
        redirect: { name: "apps-monitoring-list" },
        children: [
          {
            path: "monitoring-list",
            name: "apps-monitoring-list",
            component: () =>
              import("@/views/apps/monitoring/MonitoringList.vue"),
            meta: {
              module: "monitoring_plan",
              action: "read",
            },
          },
          {
            path: "monitoring-add",
            name: "apps-monitoring-add",
            component: () =>
              import("@/views/apps/monitoring/AddMonitoring.vue"),
            meta: {
              module: "monitoring_plan",
              action: "write",
            },
          },
          {
            path: "monitoring-edit/:id",
            name: "apps-monitoring-edit",
            component: () =>
              import("@/views/apps/monitoring/AddMonitoring.vue"),
            meta: {
              module: "monitoring_plan",
              action: "write",
            },
          },
          {
            path: "monitoring-show/:id",
            name: "apps-monitoring-show",
            component: () =>
              import("@/views/apps/monitoring/MonitoringShow.vue"),
            meta: {
              module: "monitoring_plan",
              action: "write",
            },
          },
        ],
      },
      {
        path: "/apps/monitoring/normes",
        name: "apps-monitoring-normes",
        component: () => import("@/views/apps/monitoring/NormeMonitoring.vue"),
        meta: {
          module: "analysis_standards",
          action: "write",
        },
      },
      {
        path: "/apps/monitoring/analyses",
        name: "apps-monitoring-analyses",
        component: () =>
          import("@/views/apps/monitoring/AnalysesDataTable.vue"),
        meta: {
          module: "analyses_results",
          action: "read",
        },
      },
      {
        path: "/apps/point",
        name: "apps-point-root",
        redirect: { name: "apps-point-list-action" },
        children: [
          {
            path: "list-action/:id?",
            name: "apps-point-list-action",
            component: () =>
              import("@/views/apps/monitoring/PlanActionList.vue"),
            meta: {
              module: "env_monitoring_action_plan",
              action: "read",
            },
          },
          {
            path: "add-action",
            name: "apps-point-add-action",
            component: () => import("@/views/apps/monitoring/AddAction.vue"),
            meta: {
              module: "env_monitoring_action_plan",
              action: "write",
            },
          },
          {
            path: "add-action-point/:id_pt",
            name: "apps-point-add-action-point",
            component: () => import("@/views/apps/monitoring/AddAction.vue"),
            meta: {
              module: "env_monitoring_action_plan",
              action: "write",
            },
          },
          {
            path: "edit-action-analyse_point/:id",
            name: "apps-analyse_point-edit-action-point",
            component: () => import("@/views/apps/monitoring/EditAction.vue"),
            meta: {
              module: "env_monitoring_action_plan",
              action: "write",
            },
          },
          {
            path: ":id_pt/add-action",
            name: "apps-point-add-action-id_pt",
            component: () => import("@/views/apps/monitoring/AddAction.vue"),
            meta: {
              module: "env_monitoring_action_plan",
              action: "write",
            },
          },
        ],
      },
      {
        path: "/apps/monitoring/indicateur-chart",
        name: "apps-monitoring-indicateur-chart",
        component: () => import("@/views/apps/monitoring/IndicateurCharts.vue"),
        meta: {
          module: "env_monitoring_indicators",
          action: "read",
        },
      },

      /* -------------------------------------------------------------------------- */
      /*                            module monitoring end                           */
      /* -------------------------------------------------------------------------- */
      /* -------------------------------------------------------------------------- */
      /*                               incidents start                              */
      /* -------------------------------------------------------------------------- */

      {
        path: "/apps/incidents",
        name: "apps-incident-flash-root",
        redirect: { name: "apps-incident-flash-list" },
        children: [
          {
            path: "flash-list",
            name: "apps-incident-flash-list",
            component: () => import("@/views/apps/incidents/FlashList.vue"),
            meta: {
              module: "flash_incidents",
              action: "read",
            },
          },
          {
            path: "add-flash",
            name: "apps-add-flash",
            component: () => import("@/views/apps/incidents/AddFlash.vue"),
            meta: {
              module: "flash_incidents",
              action: "write",
            },
          },
          {
            path: "edit-flash/:id",
            name: "apps-incident-edit-flash",
            component: () => import("@/views/apps/incidents/EditFlash.vue"),
            meta: {
              module: "flash_incidents",
              action: "write",
            },
          },
        ],
      },
      {
        path: "/apps/incidents",
        name: "apps-incident-registre-root",
        redirect: { name: "apps-incident-registre-list" },
        children: [
          {
            path: "registre-list",
            name: "apps-incident-registre-list",
            component: () =>
              import("@/views/apps/incidents/RegistreIncidentList.vue"),
            meta: {
              module: "env_incidents_register",
              action: "read",
            },
          },
          {
            path: "/apps/incidents/incident",
            name: "apps-incident",
            component: () => import("@/views/apps/incidents/IncidentForm.vue"),
            meta: {
              module: "env_incidents_register",
              action: "write",
            },
          },
        ],
      },
      {
        path: "/apps/incidents",
        name: "apps-incident-analyse-root",
        redirect: { name: "apps-incident-analyse-list" },
        children: [
          {
            path: "analyse-list/:id_flash?",
            name: "apps-incident-analyse-list",
            component: () => import("@/views/apps/incidents/AnalyseList.vue"),
            meta: {
              module: "env_incidents_analysis",
              action: "read",
            },
          },
          {
            path: "add-analyse/:id?",
            name: "apps-incident-add-analyse",
            component: () => import("@/views/apps/incidents/AnalyseForm.vue"),
            meta: {
              module: "env_incidents_analysis",
              action: "write",
            },
          },
          {
            path: "edit-analyse/:id",
            name: "apps-incident-edit-analyse",
            component: () => import("@/views/apps/incidents/AnalyseForm.vue"),
            meta: {
              module: "env_incidents_analysis",
              action: "write",
            },
          },
          {
            path: "analyse/:id",
            name: "apps-incident-analyse-show",
            component: () => import("@/views/apps/incidents/IncidentShow.vue"),
            meta: {
              module: "env_incidents_analysis",
              action: "read",
            },
          },
        ],
      },

      // {
      //   path: "/apps/incidents/flash/:id",
      //   name: "apps-incident-flash-show",
      //   component: () => import("@/views/apps/incidents/FlashShow.vue"),
      //   meta: {
      //     module: "incident",
      //     action: "read",
      //   },
      // },
      {
        path: "/apps/incidents",
        name: "apps-incident-action-root",
        redirect: { name: "apps-incident-list-action" },
        children: [
          {
            path: "list-action/:id?",
            name: "apps-incident-list-action",
            component: () =>
              import("@/views/apps/incidents/PlanActionList.vue"),
            meta: {
              module: "env_incidents_action_plan",
              action: "read",
            },
          },
          {
            path: "add-action",
            name: "apps-incident-add-action",
            component: () => import("@/views/apps/incidents/AddAction.vue"),
            meta: {
              module: "env_incidents_action_plan",
              action: "write",
            },
          },
          {
            path: "edit-action-incidents/:id",
            name: "apps-incident-edit-action-incident",
            component: () => import("@/views/apps/incidents/EditAction.vue"),
            meta: {
              module: "env_incidents_action_plan",
              action: "write",
            },
          },
          {
            path: ":id_cause/add-action",
            name: "apps-incident-add-action-id_cause",
            component: () => import("@/views/apps/incidents/AddAction.vue"),
            meta: {
              module: "env_incidents_action_plan",
              action: "write",
            },
          },
        ],
      },

      {
        path: "/apps/incidents/indicateur-chart",
        name: "apps-indicateur-chart",
        component: () => import("@/views/apps/incidents/IndicateurChart.vue"),
        meta: {
          module: "env_incidents_indicators",
          action: "read",
        },
      },
      /* -------------------------------------------------------------------------- */
      /*                                incidents end                               */
      /* -------------------------------------------------------------------------- */
      /* -------------------------------------------------------------------------- */
      /*                            module energie start                            */
      /* -------------------------------------------------------------------------- */
      {
        path: "/apps/consumption/fuel-list",
        name: "apps-consumption-fuel-list",
        component: () =>
          import("@/views/apps/consumption/FuelConsumptionList.vue"),
        meta: {
          module: "fuel_consumption",
          action: "read",
        },
      },
      {
        path: "/apps/consumption/fuel-indicateur",
        name: "apps-consumption-fuel-indicateur",
        component: () => import("@/views/apps/consumption/IndicateurFuel.vue"),
        meta: {
          module: "fuel_consumption_indicators",
          action: "read",
        },
      },
      // {
      //   path: "/apps/consumption/fuel-action",
      //   name: "apps-consumption-fuel-action",
      //   component: () =>
      //     import("@/views/apps/consumption/PlanActionListFuel.vue"),
      //   meta: {
      //     module: "energie",
      //     action: "read",
      //   },
      // },
      // {
      //   path: "/apps/consumption/fuel-add-action",
      //   name: "apps-consumption-fuel-add-action",
      //   component: () => import("@/views/apps/consumption/AddActionFuel.vue"),
      //   meta: {
      //     module: "energie",
      //     action: "write",
      //   },
      // },
      // {
      //   path: "/apps/consumption/fuel-edit-action/:id",
      //   name: "apps-consumption-fuel-edit-action",
      //   component: () => import("@/views/apps/consumption/EditActionFuel.vue"),
      //   meta: {
      //     module: "energie",
      //     action: "write",
      //   },
      // },
      {
        path: "/apps/consumption/electricity-list",
        name: "apps-consumption-electricity-list",
        component: () =>
          import("@/views/apps/consumption/ElectricityConsumptionList.vue"),
        meta: {
          module: "electricity_consumption",
          action: "read",
        },
      },
      {
        path: "/apps/consumption/electricity-indicateur",
        name: "apps-consumption-electricity-indicateur",
        component: () =>
          import("@/views/apps/consumption/IndicateurElectricity.vue"),
        meta: {
          module: "electricity_consumption_indicators",
          action: "read",
        },
      },
      // {
      //   path: "/apps/consumption/electricity-action",
      //   name: "apps-consumption-electricity-action",
      //   component: () =>
      //     import("@/views/apps/consumption/PlanActionListElectricity.vue"),
      //   meta: {
      //     module: "energie",
      //     action: "read",
      //   },
      // },
      // {
      //   path: "/apps/consumption/electricity-add-action",
      //   name: "apps-consumption-electricity-add-action",
      //   component: () =>
      //     import("@/views/apps/consumption/AddActionElectricity.vue"),
      //   meta: {
      //     module: "energie",
      //     action: "read",
      //   },
      // },
      // {
      //   path: "/apps/consumption/electricity-edit-action/:id",
      //   name: "apps-consumption-electricity-edit-action",
      //   component: () =>
      //     import("@/views/apps/consumption/EditActionElectricity.vue"),
      //   meta: {
      //     module: "energie",
      //     action: "write",
      //   },
      // },
      /* -------------------------------------------------------------------------- */
      /*                             module energie end                             */
      /* -------------------------------------------------------------------------- */
      /* -------------------------------------------------------------------------- */
      /*                              module eau start                              */
      /* -------------------------------------------------------------------------- */
      {
        path: "/apps/consumption/water-indicateur",
        name: "apps-consumption-water-indicateur",
        component: () => import("@/views/apps/consumption/IndicateurWater.vue"),
        meta: {
          module: "water_consumption_indicators",
          action: "read",
        },
      },
      {
        path: "/apps/consumption/water-list",
        name: "apps-consumption-water-list",
        component: () =>
          import("@/views/apps/consumption/WaterConsumptionList.vue"),
        meta: {
          module: "water_consumption",
          action: "read",
        },
      },
      // {
      //   path: "/apps/consumption/water-action",
      //   name: "apps-consumption-water-action",
      //   component: () =>
      //     import("@/views/apps/consumption/PlanActionListWater.vue"),
      //   meta: {
      //     module: "eau",
      //     action: "write",
      //   },
      // },
      // {
      //   path: "/apps/consumption/water-add-action",
      //   name: "apps-consumption-water-add-action",
      //   component: () => import("@/views/apps/consumption/AddActionWater.vue"),
      // },
      // {
      //   path: "/apps/consumption/water-edit-action/:id",
      //   name: "apps-consumption-water-edit-action",
      //   component: () => import("@/views/apps/consumption/EditActionWater.vue"),
      // },
      /* -------------------------------------------------------------------------- */
      /*                               module eau end                               */
      /* -------------------------------------------------------------------------- */
      /* -------------------------------------------------------------------------- */
      /*                         module non-conformite start                        */
      /* -------------------------------------------------------------------------- */
      {
        path: "/apps/non-conformite",
        name: "apps-non-conformite-root",
        redirect: { name: "apps-non-conformite-list" },
        children: [
          {
            path: "list",
            name: "apps-non-conformite-list",
            component: () =>
              import("@/views/apps/non-conformite/NonConformiteList.vue"),
            meta: {
              module: "view_non_conformities",
              action: "read",
            },
          },
          {
            path: "add",
            name: "apps-non-conformite-add",
            component: () =>
              import("@/views/apps/non-conformite/NonConformiteForm.vue"),
            meta: {
              module: "create_non_conformity",
              action: "write",
            },
          },
          {
            path: "edit/:id",
            name: "apps-non-conformite-edit",
            // component: () =>
            //   import("@/views/apps/non-conformite/NonConformiteEdit.vue"),
            component: () =>
              import("@/views/apps/non-conformite/NonConformiteForm.vue"),
            meta: {
              module: "view_non_conformities",
              action: "write",
            },
          },
          {
            path: "show/:id",
            name: "apps-non-conformite-show",
            component: () =>
              import("@/views/apps/non-conformite/NonConformiteShow.vue"),
            meta: {
              module: "non-conformite",
              action: "read",
            },
          },
        ],
      },

      {
        path: "/apps/non-conformite",
        name: "apps-non-conformite-action-root",
        redirect: { name: "apps-non-conformite-list-action" },
        children: [
          {
            path: "list-action/:id?",
            name: "apps-non-conformite-list-action",
            component: () =>
              import("@/views/apps/non-conformite/PlanActionList.vue"),
            meta: {
              module: "non_conformity_action_plan",
              action: "read",
            },
          },
          {
            path: "add-action",
            name: "apps-non-conformite-add-action",
            component: () =>
              import("@/views/apps/non-conformite/AddAction.vue"),
            meta: {
              module: "non_conformity_action_plan",
              action: "write",
            },
          },
          {
            path: "edit-action-non-conformite/:id",
            name: "apps-non-conformite-edit-action",
            // component: () => import("@/views/apps/non-conformite/EditAction.vue"),
            component: () =>
              import("@/views/apps/non-conformite/AddAction.vue"),
            meta: {
              module: "non_conformity_action_plan",
              action: "write",
            },
          },
          {
            path: ":id_cause/add-action",
            name: "apps-non-conformite-add-action-id_cause",
            component: () =>
              import("@/views/apps/non-conformite/AddAction.vue"),
            meta: {
              module: "non-conformite",
              action: "write",
            },
          },
        ],
      },
      {
        path: "/apps/non-conformite/indicateur",
        name: "apps-non-conformite-indicateur",
        component: () =>
          import("@/views/apps/non-conformite/IndicateurCharts.vue"),
        meta: {
          module: "non_conformity_indicators",
          action: "read",
        },
      },
      /* -------------------------------------------------------------------------- */
      /*                          module non-conformite end                         */
      /* -------------------------------------------------------------------------- */
      /* -------------------------------------------------------------------------- */
      /*                             module audits start                            */
      /* -------------------------------------------------------------------------- */
      {
        name: "apps-audit-calendar",
        path: "/apps/audit/calendar",
        component: () => import("@/views/apps/audit/CalendrierAudit.vue"),
        meta: {
          module: "audit_program",
          action: "read",
        },
      },

      {
        path: "/apps/audit",
        name: "apps-audit-root",
        redirect: { name: "apps-audit-my-list" },
        children: [
          {
            name: "apps-audit-my-list",
            path: "my-list",
            component: () => import("@/views/apps/audit/MesAudits.vue"),
            meta: {
              module: "edit_audit_report",
              action: "read",
            },
          },
          {
            name: "apps-audit-show-rapport",
            path: "rapport/:id",
            component: () => import("@/views/apps/audit/ShowRapport.vue"),
            meta: {
              module: "edit_audit_report",
              action: "read",
            },
          },
          {
            name: "apps-audit-add-rapport",
            path: ":id/add-rapport",
            component: () => import("@/views/apps/audit/NewRapport.vue"),
            meta: {
              module: "edit_audit_report",
              action: "write",
            },
          },
        ],
      },
      {
        path: "/apps/audit",
        name: "apps-audit-rapport-root",
        redirect: { name: "apps-audit-rapport-list" },
        children: [
          {
            name: "apps-audit-rapport-list",
            path: "list-rapport",
            component: () => import("@/views/apps/audit/RapportsList.vue"),
            meta: {
              module: "view_audit_reports",
              action: "read",
            },
          },
          {
            name: "apps-audit-rapport-show-rapport",
            path: "show-rapport/:id",
            component: () => import("@/views/apps/audit/ShowRapport.vue"),
            meta: {
              module: "edit_audit_report",
              action: "read",
            },
          },
        ],
      },
      {
        name: "apps-audit-auditeur-list",
        path: "/apps/audit/list-auditeur",
        component: () => import("@/views/apps/audit/ListAuditeurs.vue"),
        meta: {
          module: "view_auditors_list",
          action: "read",
        },
      },
      {
        name: "apps-audit-notation",
        path: "/apps/audit/notation",
        component: () => import("@/views/apps/audit/NotationAuditeur.vue"),
        meta: {
          module: "view_auditor_evaluations",
          action: "read",
        },
      },

      {
        path: "/apps/audit",
        name: "apps-audit-action-root",
        redirect: { name: "apps-audit-list-action" },
        children: [
          {
            name: "apps-audit-list-action",
            path: "list-action/:id?",
            component: () => import("@/views/apps/audit/PlanActionList.vue"),
            meta: {
              module: "audit_action_plan",
              action: "read",
            },
          },
          {
            name: "apps-audit-add-action",
            path: "add-action",
            component: () => import("@/views/apps/audit/AddAction.vue"),
            meta: {
              module: "audit_action_plan",
              action: "write",
            },
          },
          {
            path: "edit-action-audit/:id",
            name: "apps-audit-edit-action-audit",
            component: () => import("@/views/apps/audit/EditAction.vue"),
            meta: {
              module: "audit_action_plan",
              action: "write",
            },
          },
        ],
      },
      {
        name: "apps-audit-indicateur",
        path: "/apps/audit/indicateur",
        component: () => import("@/views/apps/audit/Indicateur.vue"),
        meta: {
          module: "audit_indicators",
          action: "read",
        },
      },

      {
        path: "/apps/audit/show-analyse/:id",
        name: "apps-audit-show-analyse",
        component: () => import("@/views/apps/analyse/AnalyseEnvShow.vue"),
        meta: {
          module: "analyse",
          action: "read",
        },
      },
      /* -------------------------------------------------------------------------- */
      /*                              module audits end                             */
      /* -------------------------------------------------------------------------- */
      /* -------------------------------------------------------------------------- */
      /*                            module dechets start                            */
      /* -------------------------------------------------------------------------- */
      {
        name: "apps-dechet-list",
        path: "/apps/dechet/list",
        component: () => import("@/views/apps/dechets/DechetList.vue"),
        meta: {
          module: "waste_report",
          action: "read",
        },
      },
      {
        name: "apps-dechet-month",
        path: "/apps/dechet/month",
        component: () => import("@/views/apps/dechets/DechetMonth.vue"),
        meta: {
          module: "monthly_waste_management",
          action: "read",
        },
      },
      {
        name: "apps-dechet-indicateur",
        path: "/apps/dechet/indicateur",
        component: () => import("@/views/apps/dechets/DechetIndicateur.vue"),
        meta: {
          module: "waste_indicators",
          action: "read",
        },
      },
      {
        name: "apps-dechet-bordereau",
        path: "/apps/dechet/bordereau",
        component: () => import("@/views/apps/dechets/DechetBordereau.vue"),
        meta: {
          module: "waste_forms",
          action: "read",
        },
      },
      /* -------------------------------------------------------------------------- */
      /*                             module dechets end                             */
      /* -------------------------------------------------------------------------- */
      /* -------------------------------------------------------------------------- */
      /*                             module moyens start                            */
      /* -------------------------------------------------------------------------- */
      {
        path: "/apps/suivi",
        name: "apps-suivi-root",
        redirect: { name: "apps-suivi-list" },
        children: [
          {
            name: "apps-suivi-list",
            path: "list",
            component: () => import("@/views/apps/suivi/SuiviEquipment.vue"),
            meta: {
              module: "equipment_requests_follow_up",
              action: "read",
            },
          },
          {
            name: "apps-suivi-add-achat",
            path: "achat/add",
            component: () => import("@/views/apps/suivi/AddAchat.vue"),
            meta: {
              module: "equipment_requests_follow_up",
              action: "write",
            },
          },
          {
            name: "apps-suivi-edit-achat",
            path: "achat/edit/:id",
            component: () => import("@/views/apps/suivi/AddAchat.vue"),
            meta: {
              module: "equipment_requests_follow_up",
              action: "write",
            },
          },
        ],
      },
      {
        name: "apps-suivi-sous-traitant",
        path: "/apps/suivi/sous-traitant",
        component: () => import("@/views/apps/suivi/SousTraitant.vue"),
        meta: {
          module: "subcontractors_follow_up",
          action: "read",
        },
      },
      {
        name: "apps-suivi-monitoring",
        path: "/apps/suivi/monitoring",
        component: () => import("@/views/apps/suivi/EquipmentMonitoring.vue"),
        meta: {
          module: "equipment_monitoring",
          action: "read",
        },
      },
      /* -------------------------------------------------------------------------- */
      /*                              module moyens end                             */
      /* -------------------------------------------------------------------------- */
      /* -------------------------------------------------------------------------- */
      /*                            module settings start                           */
      /* -------------------------------------------------------------------------- */
      {
        path: "/apps/settings/add-settings",
        name: "apps-settings-add-list",
        component: () => import("@/views/apps/settings/AddSettings.vue"),
        meta: {
          module: "parametrage",
          action: "read",
        },
      },
      /* -------------------------------------------------------------------------- */
      /*                             module settings end                            */
      /* -------------------------------------------------------------------------- */
      /* -------------------------------------------------------------------------- */
      /*                             module users start                             */
      /* -------------------------------------------------------------------------- */
      {
        path: "/apps/users/users-dashboard",
        name: "apps-users-dashboard",
        component: () => import("@/views/apps/users/UsersDashboard.vue"),
        meta: {
          module: "utilisateur",
          action: "read",
        },
      },
      /* -------------------------------------------------------------------------- */
      /*                              module users end                              */
      /* -------------------------------------------------------------------------- */
      /* -------------------------------------------------------------------------- */
      /*                          module permissions start                          */
      /* -------------------------------------------------------------------------- */
      {
        path: "/apps/settings/permission-settings",
        name: "apps-settings-permission-list",
        component: () => import("@/views/apps/settings/PermissionSettings.vue"),
        meta: {
          module: "permission",
          action: "read",
        },
      },
      /* -------------------------------------------------------------------------- */
      /*                           module permissions end                           */
      /* -------------------------------------------------------------------------- */

      /* -------------------------------------------------------------------------- */
      /*                         module notifications start                         */
      /* -------------------------------------------------------------------------- */
      {
        path: "/notification",
        name: "apps-notification-list",
        component: () => import("@/views/notification/NotificationList.vue"),
      },
      /* -------------------------------------------------------------------------- */
      /*                           module notification end                          */
      /* -------------------------------------------------------------------------- */
      /* -------------------------------------------------------------------------- */
      /*                            module profile start                            */
      /* -------------------------------------------------------------------------- */
      {
        path: "/apps/account/profile",
        name: "apps-user-profile",
        component: () => import("@/views/apps/account/UserShow.vue"),
      },
      /* -------------------------------------------------------------------------- */
      /*                             module profile end                             */
      /* -------------------------------------------------------------------------- */
      // {
      //   path: "/apps/chat/drawer-chat",
      //   name: "apps-drawer-chat",
      //   component: () => import("@/views/apps/chat/DrawerChat.vue"),
      // },
      // {
      //   path: "/crafted/modals/general/invite-friends",
      //   name: "modals-general-invite-friends",
      //   component: () =>
      //     import("@/views/crafted/modals/general/InviteFriends.vue"),
      // },
      // {
      //   path: "/crafted/modals/general/view-user",
      //   name: "modals-general-view-user",
      //   component: () => import("@/views/crafted/modals/general/ViewUsers.vue"),
      // },
      // {
      //   path: "/crafted/modals/general/upgrade-plan",
      //   name: "modals-general-upgrade-plan",
      //   component: () =>
      //     import("@/views/crafted/modals/general/UpgradePlan.vue"),
      // },
      // {
      //   path: "/crafted/modals/general/share-and-earn",
      //   name: "modals-general-share-and-earn",
      //   component: () =>
      //     import("@/views/crafted/modals/general/ShareAndEarn.vue"),
      // },
      // {
      //   path: "/crafted/modals/forms/new-target",
      //   name: "modals-forms-new-target",
      //   component: () => import("@/views/crafted/modals/forms/NewTarget.vue"),
      // },
      // {
      //   path: "/crafted/modals/forms/new-card",
      //   name: "modals-forms-new-card",
      //   component: () => import("@/views/crafted/modals/forms/NewCard.vue"),
      // },
      // {
      //   path: "/crafted/modals/forms/new-address",
      //   name: "modals-forms-new-address",
      //   component: () => import("@/views/crafted/modals/forms/NewAddress.vue"),
      // },
      // {
      //   path: "/crafted/modals/forms/create-api-key",
      //   name: "modals-forms-create-api-key",
      //   component: () =>
      //     import("@/views/crafted/modals/forms/CreateApiKey.vue"),
      // },
      // {
      //   path: "/crafted/modals/wizards/two-factor-auth",
      //   name: "modals-wizards-two-factor-auth",
      //   component: () =>
      //     import("@/views/crafted/modals/wizards/TwoFactorAuth.vue"),
      // },
      // {
      //   path: "/crafted/modals/wizards/create-app",
      //   name: "modals-wizards-create-app",
      //   component: () => import("@/views/crafted/modals/wizards/CreateApp.vue"),
      // },
      // {
      //   path: "/crafted/modals/wizards/create-account",
      //   name: "modals-wizards-create-account",
      //   component: () =>
      //     import("@/views/crafted/modals/wizards/CreateAccount.vue"),
      // },
      // {
      //   path: "/crafted/widgets/lists",
      //   name: "widgets-list",
      //   component: () => import("@/views/crafted/widgets/Lists.vue"),
      // },
      // {
      //   path: "/crafted/widgets/statistics",
      //   name: "widgets-statistics",
      //   component: () => import("@/views/crafted/widgets/Statistics.vue"),
      // },
      // {
      //   path: "/crafted/widgets/charts",
      //   name: "widgets-charts",
      //   component: () => import("@/views/crafted/widgets/Charts.vue"),
      // },
      // {
      //   path: "/crafted/widgets/mixed",
      //   name: "widgets-mixed",
      //   component: () => import("@/views/crafted/widgets/Mixed.vue"),
      // },
      // {
      //   path: "/crafted/widgets/tables",
      //   name: "widgets-tables",
      //   component: () => import("@/views/crafted/widgets/Tables.vue"),
      // },
      // {
      //   path: "/crafted/widgets/feeds",
      //   name: "widgets-feeds",
      //   component: () => import("@/views/crafted/widgets/Feeds.vue"),
      // },
      // {
      //   path: "/builder",
      //   name: "builder",
      //   component: () => import("@/views/Builder.vue"),
      // },
      // {
      //   path: "/crafted/pages/profile",
      //   name: "profile",
      //   component: () => import("@/components/page-layouts/Profile.vue"),
      //   children: [
      //     {
      //       path: "projects",
      //       name: "profile-projects",
      //       component: () =>
      //         import("@/views/crafted/pages/profile/Projects.vue"),
      //     },
      //     {
      //       path: "campaigns",
      //       name: "profile-campaigns",
      //       component: () =>
      //         import("@/views/crafted/pages/profile/Campaigns.vue"),
      //     },
      //     {
      //       path: "documents",
      //       name: "profile-documents",
      //       component: () =>
      //         import("@/views/crafted/pages/profile/Documents.vue"),
      //     },
      //     {
      //       path: "connections",
      //       name: "profile-connections",
      //       component: () =>
      //         import("@/views/crafted/pages/profile/Connections.vue"),
      //     },
      //     {
      //       path: "activity",
      //       name: "profile-activity",
      //       component: () =>
      //         import("@/views/crafted/pages/profile/Activity.vue"),
      //     },
      //   ],
      // },
    ],
  },
  {
    path: "/",
    component: () => import("@/components/page-layouts/Auth.vue"),
    children: [
      {
        path: "/sign-in",
        name: "sign-in",
        component: () =>
          import("@/views/crafted/authentication/basic-flow/SignIn.vue"),
      },
    ],
  },
  {
    path: "/404",
    name: "404",
    component: () => import("@/views/crafted/authentication/Error404.vue"),
  },
  {
    path: "/500",
    name: "500",
    component: () => import("@/views/crafted/authentication/Error500.vue"),
  },
  {
    path: "/:pathMatch(.*)*",
    redirect: "/404",
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

router.beforeEach((to, from, next) => {
  store.commit(Mutations.RESET_LAYOUT_CONFIG);
  store
    .dispatch(Actions.VERIFY_AUTH, {
      api_token: JwtService.getToken(),
    })
    .then(() => {
      // console.log("auth verified");
    })
    .finally(() => {
      if (!store.state.AuthModule.isAuthenticated && to.name !== "sign-in") {
        next({ name: "sign-in" });
      } else if (store.state.AuthModule.user && to.meta.module) {
        const userPermissions = getUserPermissions();
        // console.log(userPermissions);
        const module = to?.meta?.module as string;
        if (!userPermissions[module]?.includes(to.meta.action)) {
          next({ name: "404" });
        } else {
          next();
        }
      } else {
        next();
      }
      window.scrollTo(0, 0);
    });
});

export default router;
