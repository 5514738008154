import store from "@/store";

export const modules = [
  {
    name: "top management dashboard",
    restrictedActions: ["write", "import", "extract"],
  },
  {
    name: "dashboard",
    restrictedActions: ["write", "import"],
  },
  {
    name: "geovision",
    restrictedActions: ["write", "import", "extract"],
  },
  {
    name: "analyse",
    restrictedActions: ["write", "import", "extract"],

    submodules: [
      { name: "Consulter l'analyse environnementale" },
      {
        name: "Ajouter un aspect environnemental",
        restrictedActions: ["read", "import", "extract"],
      },
      { name: "Plan d'action d'analyse environnementale" },
      {
        name: "Indicateurs d'analyse environnementale",
        restrictedActions: ["write", "import"],
      },
    ],
  },
  {
    name: "reglementation",
    restrictedActions: ["write", "import", "extract"],

    submodules: [
      { name: "Consulter les exigences réglementaires" },
      { name: "Consulter les autres exigences" },
      { name: "Autorisations" },
      { name: "Plan d'action d'exigence" },
      {
        name: "Indicateurs d'exigence",
        restrictedActions: ["write", "import"],
      },
    ],
  },
  {
    name: "monitoring",
    restrictedActions: ["write", "import", "extract"],

    submodules: [
      { name: "Plan de surveillance" },
      { name: "Normes des analyses" },
      { name: "Analyses & Résultats" },
      { name: "Plan d'action monitoring environnemental" },
      {
        name: "Indicateurs du monitoring environnemental",
        restrictedActions: ["write", "import"],
      },
    ],
  },
  {
    name: "incident",
    restrictedActions: ["write", "import", "extract"],

    submodules: [
      { name: "Flash incidents" },
      { name: "Registre des incidents environnementaux" },
      { name: "Analyses des incidents environnementaux" },
      { name: "Plan d'action des incidents environnementaux" },
      {
        name: "Indicateurs des incidents environnementaux",
        restrictedActions: ["write", "import"],
      },
    ],
  },
  {
    name: "energie",
    restrictedActions: ["write", "import", "extract"],

    submodules: [
      { name: "Consommation de combustibles" },
      {
        name: "Indicateurs de consommation de combustibles",
        restrictedActions: ["write", "import"],
      },
      { name: "Consommation électrique" },
      {
        name: "Indicateurs de consommation électrique",
        restrictedActions: ["write", "import"],
      },
    ],
  },
  {
    name: "eau",
    restrictedActions: ["write", "import", "extract"],

    submodules: [
      { name: "Consommation des eaux" },
      {
        name: "Indicateurs de consommation des eaux",
        restrictedActions: ["write", "import"],
      },
    ],
  },
  {
    name: "non-conformite",
    restrictedActions: ["write", "import", "extract"],

    submodules: [
      { name: "Consulter les non conformités" },
      { name: "Créer une non conformité" },
      { name: "Plan d'action non conformité" },
      {
        name: "Indicateurs des non conformités",
        restrictedActions: ["write", "import"],
      },
    ],
  },
  {
    name: "audit",
    restrictedActions: ["write", "import", "extract"],

    submodules: [
      { name: "Programme des audits" },
      { name: "Éditer un rapport d'audit" },
      { name: "Consulter les rapports d'audit" },
      { name: "Consulter la liste des auditeurs" },
      { name: "Consulter les évaluations des auditeurs" },
      { name: "Plan d'action d'audit" },
      { name: "Indicateurs d'audit", restrictedActions: ["write", "import"] },
    ],
  },

  {
    name: "dechet",
    restrictedActions: ["write", "import", "extract"],

    submodules: [
      { name: "Bilan des déchets" },
      { name: "Gestion mensuelle des déchets" },
      { name: "Indicateurs de déchet", restrictedActions: ["write", "import"] },
      { name: "Bordereaux des déchets" },
    ],
  },
  {
    name: "moyens",
    restrictedActions: ["write", "import", "extract"],

    submodules: [
      { name: "Suivi des demandes d'équipements" },
      { name: "Suivi des sous-traitants" },
      { name: "Monitoring des équipements" },
    ],
  },
  { name: "parametrage", restrictedActions: ["write", "import", "extract"] },
  { name: "utilisateur", restrictedActions: ["write", "import", "extract"] },
  { name: "permission", restrictedActions: ["write", "import", "extract"] },
];

export const getUserPermissions = () => {
  const user = store.state.AuthModule.user;
  const userPermissions = {};
  user?.role?.permissions?.forEach(
    (permission: { action: string; name: string }) => {
      if (userPermissions[permission.name]) {
        userPermissions[permission.name].push(permission.action);
      } else {
        userPermissions[permission.name] = [permission.action];
      }
    }
  );
  return userPermissions;
};

export const getUserRole = () => {
  const user = store.state.AuthModule.user;
  return user?.role?.name;
};

const DocMenuConfig = () => {
  const userPermissions = getUserPermissions();
  const menu = [
    {
      pages: [
        {
          heading: "Top Management Dashboard",
          route: "/tm_dashboard",
          svgIcon: "media/icons/duotune/graphs/gra001.svg",
          fontIcon: "bi-app-indicator",
          show:
            userPermissions["top management dashboard"]?.includes("read") ??
            false,
        },
        {
          heading: "dashboard",
          route: "/dashboard",
          svgIcon: "media/icons/duotune/art/art002.svg",
          fontIcon: "bi-app-indicator",
          show: true,
        },
        {
          heading: "GéoVision",
          route: "/geovision",
          svgIcon: "media/icons/duotune/technology/teh008.svg",
          fontIcon: "bi-app-indicator",
          show: userPermissions["geovision"]?.includes("read") ?? false,
        },

        {
          heading: "Documentations",
          route: "/docs",
          svgIcon: "media/icons/duotune/files/fil012.svg",
          fontIcon: "bi-app-indicator",
          show: true,
        },
      ],
    },
    {
      heading: "Modules",
      route: "/apps",
      pages: [
        {
          sectionTitle: "Analyse environnementale",
          key: "analyse",
          svgIcon: "/media/icons/duotune/medicine/med005.svg",
          fontIcon: "bi-cart",
          show: userPermissions["analyse"]?.includes("read") ?? false,
          sub: [
            {
              heading: "Consulter l'analyse environnementale",
              route: { name: "apps-analyse-root" },
              show:
                userPermissions["view_env_analysis"]?.includes("read") ?? false,
            },
            // {
            //   heading: "Ajouter un aspect environnemental",
            //   route: "/apps/analyse/add-analyse",
            //   show:
            //     userPermissions["add_env_aspect"]?.includes("write") ?? false,
            // },
            {
              heading: "Plan d'action d'analyse environnementale",
              route: { name: "apps-analyse-action-root" },
              show:
                userPermissions["env_analysis_action_plan"]?.includes("read") ??
                false,
            },
            {
              heading: "Indicateurs d'analyse environnementale",
              route: "/apps/analyse/indicateur-chart",
              show:
                userPermissions["env_analysis_indicators"]?.includes("read") ??
                false,
            },
          ],
        },
        {
          sectionTitle: "Exigences réglementaires et autres exigences",
          key: "reglementation",
          svgIcon: "/media/icons/duotune/medicine/med006.svg",
          fontIcon: "bi-cart",
          show: userPermissions["reglementation"]?.includes("read") ?? false,
          sub: [
            {
              heading: "Consulter les exigences réglementaires",
              route: { name: "apps-reglementation-root" },
              show:
                userPermissions["view_regulatory_requirements"]?.includes(
                  "read"
                ) ?? false,
            },
            {
              heading: "Consulter les autres exigences",
              route: { name: "apps-autre-reglementation-root" },
              show:
                userPermissions["view_other_requirements"]?.includes("read") ??
                false,
            },
            // {
            //   heading: "Créer une exigence",
            //   route: "/apps/reglementation/reglementation-add",
            //   show: userPermissions["reglementation"]?.includes("write") ?? false,
            // },
            {
              heading: "Autorisations",
              route: "/apps/reglementation/autorisations",
              show:
                userPermissions["authorizations"]?.includes("read") ?? false,
            },
            {
              heading: "Plan d'action d'exigence",
              route: { name: "apps-reglementation-actions-root" },
              show:
                userPermissions["requirement_action_plan"]?.includes("read") ??
                false,
            },
            {
              heading: "Indicateurs d'exigence",
              route: "/apps/reglementation/indicateur-chart",
              show:
                userPermissions["requirement_indicators"]?.includes("read") ??
                false,
            },
          ],
        },
        {
          sectionTitle: "Monitoring environnemental",
          key: "monitoring",
          svgIcon: "/media/icons/duotune/medicine/med005.svg",
          fontIcon: "bi-cart",
          show: userPermissions["monitoring"]?.includes("read") ?? false,
          sub: [
            {
              heading: "Plan de surveillance",
              route: { name: "apps-monitoring-root" },
              show:
                userPermissions["monitoring_plan"]?.includes("read") ?? false,
            },
            {
              heading: "Normes des analyses",
              route: "/apps/monitoring/normes",
              show:
                userPermissions["analysis_standards"]?.includes("write") ??
                false,
            },
            {
              heading: "Analyses & Résultats",
              route: "/apps/monitoring/analyses",
              show:
                userPermissions["analyses_results"]?.includes("write") ?? false,
            },
            {
              heading: "Plan d'action monitoring environnemental",
              route: { name: "apps-point-root" },
              show:
                userPermissions["env_monitoring_action_plan"]?.includes(
                  "read"
                ) ?? false,
            },
            {
              heading: "Indicateurs du monitoring environnemental",
              route: "/apps/monitoring/indicateur-chart",
              show:
                userPermissions["env_monitoring_indicators"]?.includes(
                  "read"
                ) ?? false,
            },
          ],
        },
        {
          sectionTitle: "Incidents Environnementaux",
          key: "incident",
          svgIcon: "/media/icons/duotune/medicine/med001.svg",
          fontIcon: "bi-exclamation-circle",
          show: userPermissions["incident"]?.includes("read") ?? false,
          sub: [
            {
              heading: "Flash incidents",
              route: { name: "apps-incident-flash-root" },
              show:
                userPermissions["flash_incidents"]?.includes("write") ?? false,
            },
            {
              heading: "Registre des incidents environnementaux",
              route: { name: "apps-incident-registre-root" },
              show:
                userPermissions["env_incidents_register"]?.includes("read") ??
                false,
            },
            {
              heading: "Analyses des incidents environnementaux",
              route: { name: "apps-incident-analyse-root" },
              show:
                userPermissions["env_incidents_analysis"]?.includes("read") ??
                false,
            },
            {
              heading: "Plan d'action des incidents environnementaux",
              route: { name: "apps-incident-action-root" },
              show:
                userPermissions["env_incidents_action_plan"]?.includes(
                  "read"
                ) ?? false,
            },
            {
              heading: "Indicateurs des incidents environnementaux",
              route: "/apps/incidents/indicateur-chart",
              show:
                userPermissions["env_incidents_indicators"]?.includes("read") ??
                false,
            },
          ],
        },
        {
          sectionTitle: "Énergie",
          key: "energie",
          svgIcon: "/media/icons/duotune/sections/energy.svg",
          fontIcon: "bi-battery-charging",
          show: userPermissions["energie"]?.includes("read") ?? false,
          sub: [
            {
              heading: "Consommation de combustibles",
              route: "/apps/consumption/fuel-list",
              show:
                userPermissions["fuel_consumption"]?.includes("read") ?? false,
            },
            {
              heading: "Indicateurs de consommation de combustibles",
              route: "/apps/consumption/fuel-indicateur",
              show:
                userPermissions["fuel_consumption_indicators"]?.includes(
                  "read"
                ) ?? false,
            },
            // {
            //   heading: "Plans d'action Combustible",
            //   route: "/apps/consumption/fuel-action",
            // },
            {
              heading: "Consommation électrique",
              route: "/apps/consumption/electricity-list",
              show:
                userPermissions["electricity_consumption"]?.includes("read") ??
                false,
            },
            // {
            //   heading: "Plans d'action Électricité",
            //   route: "/apps/consumption/electricity-action",
            // },
            {
              heading: "Indicateurs de consommation électrique",
              route: "/apps/consumption/electricity-indicateur",
              show:
                userPermissions["electricity_consumption_indicators"]?.includes(
                  "read"
                ) ?? false,
            },
          ],
        },
        {
          sectionTitle: "Eau",
          key: "eau",
          svgIcon: "/media/icons/duotune/sections/water.svg",
          fontIcon: "bi-droplet-half",
          show: userPermissions["eau"]?.includes("read") ?? false,
          sub: [
            {
              heading: "Consommation des eaux",
              route: "/apps/consumption/water-list",
              show:
                userPermissions["water_consumption"]?.includes("read") ?? false,
            },
            // {
            //   heading: "Plans d'action Hydrauliques",
            //   route: "/apps/consumption/water-action",
            // },
            {
              heading: "Indicateurs de consommation des eaux",
              route: "/apps/consumption/water-indicateur",
              show:
                userPermissions["water_consumption_indicators"]?.includes(
                  "read"
                ) ?? false,
            },
          ],
        },
        {
          sectionTitle: "Non conformité",
          key: "non-conformite",
          svgIcon: "/media/icons/duotune/sections/gavel.svg",
          fontIcon: "bi-cart",
          show: userPermissions["non-conformite"]?.includes("read") ?? false,
          sub: [
            {
              heading: "Consulter les non conformités",
              route: { name: "apps-non-conformite-root" },
              show:
                userPermissions["view_non_conformities"]?.includes("read") ??
                false,
            },
            // {
            //   heading: "Créer une non conformité",
            //   route: "/apps/non-conformite/add",
            //   show:
            //     userPermissions["create_non_conformity"]?.includes("write") ??
            //     false,
            // },
            {
              heading: "Plan d'action non conformité",
              route: { name: "apps-non-conformite-action-root" },
              show:
                userPermissions["non_conformity_action_plan"]?.includes(
                  "read"
                ) ?? false,
            },
            {
              heading: "Indicateurs des non conformités",
              route: "/apps/non-conformite/indicateur",
              show:
                userPermissions["non_conformity_indicators"]?.includes(
                  "read"
                ) ?? false,
            },
          ],
        },
        {
          sectionTitle: "Audits",
          key: "audit",
          svgIcon: "/media/icons/duotune/sections/audit.svg",
          fontIcon: "bi-cart",
          show: userPermissions["audit"]?.includes("read") ?? false,
          sub: [
            {
              heading: "Programme des audits",
              route: "/apps/audit/calendar",
              show: userPermissions["audit_program"]?.includes("read") ?? false,
            },
            {
              heading: "Éditer un rapport d'audit",
              route: { name: "apps-audit-root" },
              show:
                userPermissions["edit_audit_report"]?.includes("read") ?? false,
            },
            {
              heading: "Consulter les rapports d'audit",
              route: { name: "apps-audit-rapport-root" },
              show:
                userPermissions["view_audit_reports"]?.includes("read") ??
                false,
            },
            {
              heading: "Consulter la liste des auditeurs",
              route: "/apps/audit/list-auditeur",
              show:
                userPermissions["view_auditors_list"]?.includes("read") ??
                false,
            },
            {
              heading: "Consulter les évaluations des auditeurs",
              route: "/apps/audit/notation",
              show:
                userPermissions["view_auditor_evaluations"]?.includes("read") ??
                false,
            },
            {
              heading: "Plan d'action d'audit",
              route: { name: "apps-audit-action-root" },
              show:
                userPermissions["audit_action_plan"]?.includes("read") ?? false,
            },
            {
              heading: "Indicateurs d'audit",
              route: "/apps/audit/indicateur",
              show:
                userPermissions["audit_indicators"]?.includes("read") ?? false,
            },
          ],
        },
        {
          sectionTitle: "Déchets",
          key: "dechet",
          svgIcon: "/media/icons/duotune/sections/dechet.svg",
          fontIcon: "bi-cart",
          show: userPermissions["dechet"]?.includes("read") ?? false,
          sub: [
            {
              heading: "Bilan des déchets",
              route: "/apps/dechet/list",
              show: userPermissions["waste_report"]?.includes("read") ?? false,
            },
            {
              heading: "Gestion mensuelle des déchets",
              route: "/apps/dechet/month",
              show:
                userPermissions["monthly_waste_management"]?.includes("read") ??
                false,
            },
            {
              heading: "Indicateurs de déchets",
              route: "/apps/dechet/indicateur",
              show:
                userPermissions["waste_indicators"]?.includes("read") ?? false,
            },
            {
              heading: "Bordereaux des déchets",
              route: "/apps/dechet/bordereau",
              show: userPermissions["waste_forms"]?.includes("read") ?? false,
            },
          ],
        },
        {
          sectionTitle: "Moyens",
          key: "moyens",
          svgIcon: "/media/icons/duotune/sections/compass.svg",
          fontIcon: "bi-cart",
          show: userPermissions["moyens"]?.includes("read") ?? false,
          sub: [
            {
              heading: "Suivi des demandes d'équipements",
              route: { name: "apps-suivi-root" },
              show:
                userPermissions["equipment_requests_follow_up"]?.includes(
                  "read"
                ) ?? false,
            },
            {
              heading: "Suivi des sous-traitants",
              route: "/apps/suivi/sous-traitant",
              show:
                userPermissions["subcontractors_follow_up"]?.includes("read") ??
                false,
            },
            {
              heading: "Monitoring des équipements",
              route: "/apps/suivi/monitoring",
              show:
                userPermissions["equipment_monitoring"]?.includes("read") ??
                false,
            },
          ],
        },
      ],
    },
  ];

  menu[1].pages.forEach((page) => {
    page.sub.push({
      heading: "Documentation",
      route: "/docs/" + page.key,
      show: true,
    });
  });

  return menu;
};

export default DocMenuConfig;
